import { Configuration, LogLevel, RedirectRequest } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY ?? "",
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY ?? ""],
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export let loginRequest: RedirectRequest = {
  authority: process.env.REACT_APP_AUTHORITY,
  scopes: ["openid"],
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
  redirectStartPage: process.env.REACT_APP_REDIRECT_URL,
};

export let changeAccountRequest: RedirectRequest = {
  authority: process.env.REACT_APP_AUTHORITY,
  prompt: "select_account",
  scopes: ["openid"],
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
  redirectStartPage: process.env.REACT_APP_REDIRECT_URL,
};

export let changeMfaRequest: RedirectRequest = {
  authority: process.env.REACT_APP_AUTHORITY_MFA,
  scopes: ["openid"],
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
  redirectStartPage: process.env.REACT_APP_REDIRECT_URL,
};

export let changePhoneRequest: RedirectRequest = {
  authority: process.env.REACT_APP_AUTHORITY_PHONE,
  scopes: ["openid"],
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
  redirectStartPage: process.env.REACT_APP_REDIRECT_URL,
};
