import { useNavigate } from "react-router-dom";

export const ROOT_PATH = "/";

export const AUTH_REDIRECT_PATH = "auth-redirect";

export function useAppNavigation() {
  const navigate = useNavigate();

  const functions = {
    toHome: () => {
      navigate(`${ROOT_PATH}`);
    },
  };

  return { appNavigation: functions };
}
