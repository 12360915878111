import { useEffect } from "react";
import { useAppNavigation } from "./set-routes";

const AuthRedirect = () => {
  const { appNavigation } = useAppNavigation();

  useEffect(() => {
    appNavigation.toHome();
  }, [appNavigation]);

  return null;
};

export default AuthRedirect;
