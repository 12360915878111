import ProtectedRoute from "./protected-routes";
import AuthRedirect from "./auth-redirect";
import App from "../../App";
import { Navigate, useRoutes } from "react-router-dom";
import { AUTH_REDIRECT_PATH, ROOT_PATH } from "./set-routes";
// Assicurati che il percorso dell'import sia corretto

export default function Router() {
  const routes = useRoutes([
    {
      path: ROOT_PATH,
      element: (
        <ProtectedRoute>
          <App />
        </ProtectedRoute>
      ),
    },
    {
      path: AUTH_REDIRECT_PATH,
      element: (
        <ProtectedRoute>
          <AuthRedirect />
        </ProtectedRoute>
      ),
    },
    { path: "*", element: <Navigate to={ROOT_PATH} /> },
  ]);

  return routes;
}
