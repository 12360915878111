import React, { useEffect, useState } from "react";
import { msalInstance } from "./utils/init";
import {
  changeAccountRequest,
  changeMfaRequest,
  changePhoneRequest,
  loginRequest,
} from "./utils/api/auth/auth-config";
import { decodeJwt, getToken } from "./utils/api/auth/token";
interface Claim {
  typ: string;
  val: string;
}

interface User {
  identityProvider: string;
  userId: string;
  userDetails: string;
  userRoles: string[];
  claims: Claim[];
}

function App() {
  const [token, setToken] = useState<string>();

  useEffect(() => {
    async function fetchData() {
      setToken(await getToken());
    }
    fetchData();
  }, []);
  // Funzioni per gestire il reindirizzamento
  const handleRedirectChangeMfa = () => {
    var request = changeMfaRequest;
    request.loginHint = msalInstance.getAllAccounts()[0].username;
    msalInstance.loginRedirect(request).catch((e) => {
      console.error(e);
    });
  };
  const handleRedirectChangePhoneNumber = () => {
    var request = changePhoneRequest;
    request.loginHint = msalInstance.getAllAccounts()[0].username;
    msalInstance.loginRedirect(request).catch((e) => {
      console.error(e);
    });
  };

  function parseJwt(jwt: any) {
    try {
      // Divide il token e prende la parte del payload, che è la seconda parte
      const base64Url = jwt.split(".")[1];
      // Decodifica il payload da Base64Url a Base64
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      // Decodifica il Base64 e parsa il JSON
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Errore durante il parsing del JWT:", e);
      return null;
    }
  }
  let userClaims = parseJwt(token);
  let email = userClaims?.["verified_email"] || userClaims?.["email"];
  const name = userClaims?.["name"];
  const mfa = userClaims?.["extension_RegisterSMS"];

  return (
    <div>
      {/* TopBar */}
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Centra tutto il contenuto orizzontalmente
          alignItems: "center", // Centra tutto il contenuto verticalmente
          backgroundColor: "#13294B",
          height: "125.13px",
          paddingLeft: "30px", // Compensa il padding a sinistra
          paddingRight: "30px", // Compensa il padding a sinistra
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%", // Assicura che il div interno si estenda per tutta la larghezza
            maxWidth: "1285px", // Larghezza massima del contenuto interno
          }}
        >
          <img
            src={require("./assets/logo.png")}
            alt="Logo"
            style={{ width: "120px", height: "auto", flexGrow: 0 }}
          />
          <span style={{ color: "white" }}>{email}</span>
        </div>
      </div>

      <div
        style={{
          padding: "20px",
          background: "#f9f9f9",
          borderRadius: "10px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          maxWidth: "500px",
          margin: "20px auto",
        }}
      >
        <h1>{name}</h1>
        <p style={{ color: "#333", marginBottom: "10px" }}>Email: {email}</p>
        <p style={{ color: "#333", marginBottom: "10px" }}>
          MFA method: {mfa === "totp" ? "Microsoft Authenticator APP" : "SMS"}
        </p>
        <p />
        <button
          onMouseEnter={(e) =>
            ((e.currentTarget as HTMLElement).style.background = "#13294B")
          } // Cambia al colore di hover
          onMouseLeave={(e) =>
            ((e.currentTarget as HTMLElement).style.background = "#047895")
          } // Torna al colore originale
          onClick={handleRedirectChangeMfa}
          style={{
            padding: "10px 20px",
            height: "45.41px",
            marginRight: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            background: "#047895",
            color: "white",
          }}
        >
          Change MFA method
        </button>
        <button
          onMouseEnter={(e) =>
            ((e.currentTarget as HTMLElement).style.background = "#13294B")
          } // Cambia al colore di hover
          onMouseLeave={(e) =>
            ((e.currentTarget as HTMLElement).style.background = "#047895")
          } // Torna al colore originale
          onClick={handleRedirectChangePhoneNumber}
          style={{
            padding: "10px 20px",
            height: "45.41px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            background: "#047895",
            color: "white",
          }}
        >
          Edit phone number
        </button>
      </div>
    </div>
  );
}

export default App;
