import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ReactNode, useState, useEffect } from "react";
import { loginRequest } from "../api/auth/auth-config";
import { getToken } from "../api/auth/token";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Wait for 1 seconds

    return () => {
      clearTimeout(timer); // Clear the timer if the component unmounts
    };
  }, []);

  useEffect(() => {
    // Setting loading to false if the user is authenticated
    if (isAuthenticated) {
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      instance.loginRedirect(loginRequest).catch((e) => {});
    }
  }, [loading, isAuthenticated, instance]);

  if (loading) {
    return <div />; // Or your custom loading component
  } else if (!isAuthenticated) {
    return null;
  } else {
    return <>{children}</>;
  }
};

export default ProtectedRoute;
