import { msalInstance } from "../../init";
import { loginRequest } from "../auth/auth-config";

const TOKEN_STORAGE_KEY = "msal_token";
const ONE_SECOND = 1000; // Millisecondi
const THIRTY_MINUTES = 30 * 60 * ONE_SECOND;

// Funzione per decodificare un JWT e ottenere il payload
export function decodeJwt(jwtToken: any) {
  const payloadEncoded = jwtToken.split(".")[1];
  return JSON.parse(atob(payloadEncoded));
}

export const getToken = async (): Promise<string> => {
  // Ottieni il token dallo storage
  let token = localStorage.getItem(TOKEN_STORAGE_KEY);

  if (token) {
    const decodedToken = decodeJwt(token);
    console.log(decodedToken);
    const currentTime = Math.floor((Date.now() + THIRTY_MINUTES) / ONE_SECOND);

    // Se il token sta per scadere (ad esempio, meno di un secondo rimasto), rigeneralo
    if (decodedToken.exp < currentTime) {
      token = await generateToken();
    }
  } else {
    // Se non c'è un token nello storage, generane uno
    token = await generateToken();
  }

  return token;
};

const generateToken = async (): Promise<string> => {
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: msalInstance.getAllAccounts()[0],
      forceRefresh: true,
    });

    localStorage.setItem(TOKEN_STORAGE_KEY, response.idToken);

    return response.idToken;
  } catch (error) {
    alert("Errore durante il recupero dell'access token:" + error);
    throw error;
  }
};
